import React, { useEffect, useRef, useState } from "react";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../assets/KYC Logo white-bg 2.jpg";
import { ImLocation2 } from "react-icons/im";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { NavLink, useNavigate } from "react-router-dom";

const Termandcondition = () => {
  const [topto, settopto] = useState(0);
  const [bottomto, setbottomto] = useState(0);
  const [IsFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById("myDiv"); // आपके डिव का आईडी यहां उपयोग करें
    const end = document.getElementById("endsidebar"); // आपके डिव का आईडी यहां उपयोग करें
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const distanceFromTop = rect.top;
      settopto(distanceFromTop);
    }
    if (end) {
      const rect2 = end.getBoundingClientRect();
      const distanceFromTop2 = rect2.top;
      setbottomto(distanceFromTop2);
    }
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > topto && scrollPosition < bottomto) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  window.addEventListener("scroll", handleScroll);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);
  const section11 = useRef(null);
  const section12 = useRef(null);
  const redirecthead = (num) => {
    section1.current?.scrollIntoView({ behavior: "smooth" });
  };

  const redirecthead2 = (num) => {
    section2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead3 = (num) => {
    section3.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead4 = (num) => {
    section4.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead5 = (num) => {
    section5.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead6 = (num) => {
    section6.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead7 = (num) => {
    section7.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead8 = (num) => {
    section8.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead9 = (num) => {
    section9.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead10 = (num) => {
    section10.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead11 = (num) => {
    section11.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead12 = (num) => {
    section12.current?.scrollIntoView({ behavior: "smooth" });
  };
  const nvg = useNavigate()
  const navigatefeature = (num) =>{
  nvg('/',{state:{id:num}})
  }

  return (
    <div style={{ margin: "0px", padding: "0px" }}>
 <section>
  <nav className="navbar navbar-expand-lg navbar-light bg-body p-0" aria-label="Eighth navbar example">
    <div className="container-fluid">
      <div className="img-fluid pb-1 imgdivsize">
        <NavLink to="/">
          <img src={img3} className='imgsize' alt="logo" /></NavLink>
      </div>
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarsExample07" style={{width:'61%'}}>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(0)}} role="button" >
            Features
            </p>
          </li>
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(1)}}>Industries</p>
          </li>
          {/* <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li> */}
          <li className="nav-item dropdown">
            <a className="nav-link text-black" href="/about">About </a>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link text-black" to="/contact">Contact Us</NavLink>
          </li>
        </ul>
        
        <div className=" p-1 d-flex" style={{gap:'4px'}}>
        <li className="nav-item">
            <p className="bg-white text-primary btn btn-outline-primary" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li>
            <NavLink to="/login" className="btn btn-outline-primary">
            Log in
            </NavLink>
          {/* <button type="button" className='btn' >
            <NavLink to='/cart'>
           <FaShoppingCart style={{fontSize:'24px',color:'red'}} />
           </NavLink>
          </button> */}
        </div>
      </div>
    </div>
  </nav>
</section>

      <section class="banner-term">
        <div class="react-overlay12">
          <div class="container-fuild">
            <div class="row m-0 d-flex justify-content-center">
              <h4 class=" mt-4 bann-txt page-title ">
                Terms and Conditions of KYC First Solution LLP
              </h4>
              {/* <p className='text-white text-center spaceofx'>Our innovative KYC solutions enable businesses across various industries to verify the identities of their customers efficiently, securely, and in a user-friendly manner.</p> */}
              {/* <div className='d-flex justify-content-center'>
                        
                        <button type="button" className="btn btn-outline-primary" style={{background:'transparent',border:'1.4px solid white',color:'white',fontWeight:'500'}}>
                    Get Started
          </button>
                        </div> */}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row py-3">
            <div className="col-md-8 px-3">
              <div className="section1" style={{ width: "100%" }}>
                <p className="desc120">
                  Welcome to KYC First Solution LLP . Before using our services,
                  please carefully read and understand the following Terms and
                  Conditions. By accessing and using our services, you
                  acknowledge that you have read, understood, and agreed to be
                  bound by these terms. If you do not agree with any part of
                  these Terms and Conditions, please do not use our services.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section1}>
                  1. Definitions
                </p>
                <ul className="allowlist">
                  <li className="desc120">
                    {" "}
                    "Company," "we," "us," or "our" refers to KYC First Solution
                    LLP , the entity providing the services.
                  </li>
                  <li className="desc120">
                    {" "}
                    "User," "you," or "your" refers to any individual or entity
                    using our services.
                  </li>
                </ul>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section2}>
                  2. Services
                </p>
                <p className="desc120">
                  KYC First Solution LLP offers Know Your Customer (KYC)
                  solutions and related services for businesses to verify
                  customer identities and comply with applicable regulations.
                  These services include identity verification, document
                  verification, and compliance management.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section3}>
                  3. User Account
                </p>
                <p className="desc120 pb-2">
                  a. To access certain features of our services, you may need to
                  create a user account. You are responsible for maintaining the
                  confidentiality of your account credentials and for any
                  activity that occurs under your account.
                </p>
                <p className="desc120 pb-2">
                  b. You agree to provide accurate, current, and complete
                  information during the registration process. You must promptly
                  update your account information to ensure its accuracy.
                </p>
                <p className="desc120">
                  c. You must not share your account credentials with any third
                  party. If you suspect unauthorized use of your account, you
                  must notify us immediately.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section4}>
                  4. User Conduct
                </p>
                <p className="desc120 pb-2">
                  a. You agree not to use our services for any illegal,
                  unauthorized, or prohibited purposes. You shall comply with
                  all applicable laws and regulations while using our services.
                </p>
                <p className="desc120">
                  b. You shall not engage in any activity that may disrupt or
                  interfere with the proper functioning of our services or
                  compromise their security.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section5}>
                  5. Privacy
                </p>
                <p className="desc120">
                  Your privacy is important to us. Our Privacy Policy outlines
                  how we collect, use, disclose, and protect your personal
                  information. By using our services, you consent to the
                  practices described in our Privacy Policy.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section6}>
                  6. Intellectual Property
                </p>
                <p className="desc120 pb-2">
                  a. All intellectual property rights in our services, including
                  but not limited to software, logos, trademarks, and content,
                  belong to KYC First Solution LLP and its licensors. You shall
                  not reproduce, modify, distribute, or exploit any part of our
                  services without our prior written consent.
                </p>
                <p className="desc120">
                  b. You retain ownership of the information and data you
                  provide for identity verification. By providing such
                  information, you grant KYC First Solution LLP a non-exclusive,
                  worldwide, royalty-free license to use, process, and store the
                  data for the purposes outlined in our Privacy Policy and these
                  Terms and Conditions.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section7}>
                  7. Limitation of Liability
                </p>
                <p className="desc120 pb-2">
                  a. KYC First Solution LLP provides its services on an "as is"
                  and "as available" basis. We do not make any warranties,
                  representations, or guarantees regarding the accuracy,
                  reliability, or suitability of our services for any purpose.
                </p>
                <p className="desc120">
                  b. In no event shall KYC First Solution LLP be liable for any
                  indirect, incidental, special, consequential, or punitive
                  damages arising out of or related to your use or inability to
                  use our services.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section8}>
                  8. Indemnification
                </p>
                <p className="desc120">
                  You agree to indemnify, defend, and hold harmless KYC First
                  Solution LLP and its officers, directors, employees, and
                  agents from any claims, damages, losses, liabilities, costs,
                  or expenses (including attorney fees) arising from your use of
                  our services or violation of these Terms and Conditions.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section9}>
                  9. Modifications to the Terms
                </p>
                <p className="desc120">
                  We reserve the right to update, modify, or change these Terms
                  and Conditions at any time. The updated version will be posted
                  on our website, and your continued use of our services
                  constitutes acceptance of the revised Terms and Conditions.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section10}>
                  10. Termination
                </p>
                <p className="desc120">
                  We may, at our discretion, suspend or terminate your access to
                  our services at any time, with or without cause.
                </p>
              </div>

              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section11}>
                  11. Governing Law and Jurisdiction
                </p>
                <p className="desc120">
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of India. Any disputes arising
                  from or related to these Terms and Conditions shall be subject
                  to the exclusive jurisdiction of the courts in Pune,
                  Maharashtra, India.
                </p>
              </div>
              <div id="endsidebar"></div>
              <div className="section1 pt-2" style={{ width: "100%" }}>
                <p className="title120" ref={section12}>
                  12. Contact Us
                </p>
                <p className="desc120 pb-1">
                  If you have any questions, concerns, or inquiries regarding
                  these Terms and Conditions or our services, please contact us
                  at:
                </p>
                <p className="desc120 m-0">KYC First Solution LLP</p>
                <p className="desc120 m-0">
                  907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS
                </p>
                <p className="desc120 m-0">
                  Email:{" "}
                  <span style={{ color: "rgba(12, 83, 152, 1)" }}>
                    legal@kycfirst.io
                  </span>
                </p>
                <p className="desc120">
                  Phone:{" "}
                  <span style={{ color: "rgba(12, 83, 152, 1)" }}>
                    +91 9730074506
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-md-4"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className=" row stickylinks"
                style={{
                  width: IsFixed == false ? "107%" : "31%",
                  position: IsFixed == false ? "relative" : "fixed",
                  top: IsFixed == false ? "0px" : "14px",
                }}
              >
                <div className="col-12">
                  {" "}
                  <ul
                    class="list-group hideul"
                    style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)" }}
                  >
                    {/* <li class="list-group-item active" aria-current="true">An active item</li> */}
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead}
                    >
                      1. Definitions
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      id="myDiv"
                      onClick={redirecthead2}
                    >
                      2. Services
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead3}
                    >
                      3. User Account
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead4}
                    >
                      4. User Conduct
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead5}
                    >
                      5. Privacy
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead6}
                    >
                      6. Intellectual Property
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead7}
                    >
                      7. Limitation of Liability
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead8}
                    >
                      8. Indemnification
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead9}
                    >
                      9. Modifications to the Terms
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead10}
                    >
                      10. Termination
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead11}
                    >
                      11. Governing Law and Jurisdiction
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead12}
                    >
                      12. Contact Us
                    </li>
                  </ul>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer" style={{marginTop: '5rem'}}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3 col-md-6 col-12">
        <div className="footer-img pb-1">
          <img src={img3} style={{mixBlendMode:'darken'}} className='footwidth'  alt="logo" />
        </div>
      </div>
      {/* <div className="col-lg-2 col-md-6 col-12 ">
        <p className="customunder">Home</p>
        <p className="desc21 ">Industries</p>
      </div> */}
      <div className="col-lg-2 col-md-6 col-12 newjc" style={{display:'flex'}}>
        {/* <p className="customunder">Pricing</p> */}
        <p className="desc21 "><NavLink style={{color:'black'}} to='/contact'>Contact Us</NavLink></p>
      </div>
      <div className="col-lg-3 col-md-6 col-12 ">
      <p> <a style={{ color: "black" }} href="/about">About</a></p>

        <p className="desc21 "><a style={{color:'black'}} href="/privacypolicy"> Privacy & Policy</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/termandcondition">Terms & Condition</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/refund">Refund and Cancellation</a></p>
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <div className="single-footer">
          {/* <p className="customunder">Contact Us</p> */}
          <div className="contact_i">
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><ImLocation2 /></div>
              <div className="col-10  desc21">907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS</div>
            </div>

            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><FaEnvelope /></div>
              <div className="col-10 desc21">Email:<a className=" text-decoration-none" href="mailto:legal@kycfirst.io">
               legal@kycfirst.io</a></div>
            </div>
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><BsFillTelephoneFill /></div>
              <div className="col-10 desc21"> Phone:<a className=" text-decoration-none" href="tel:8130515661">

                 +91 9730074506</a>
              </div>
            </div>
           
          
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="row ">
      <div className="col-12" style={{display:'none'}}>
      <ul className="social-foo">
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaFacebookF /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaInstagram /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaTwitter/></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaLinkedinIn /></a></li>
        </ul>
      </div>
      <div className="col-12 mt-1 mb-1">
        <p className="text-center desc2">© 2023 - All Right Reserved</p>
      </div>
    </div>
  </div>
</footer>
    </div>
  );
};

export default Termandcondition;
