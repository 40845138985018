import React, { useEffect, useRef, useState } from 'react'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import img3 from "../assets/KYC Logo white-bg 2.jpg";
import { ImLocation2 } from "react-icons/im";
import { FaEnvelope,FaFacebookF,FaInstagram,FaTwitter,FaLinkedinIn } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { NavLink, useNavigate } from 'react-router-dom';

const Privacypolicy = () => {
 
  






  useEffect(() => {
    const handleBeforeUnload = () => {
      // Save the scroll position to localStorage before the page is unloaded
      localStorage.setItem('scrollPosition', JSON.stringify(window.scrollY));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up by removing the event listener when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // When the component mounts, check if a scroll position was saved in localStorage
    const scrollPosition = JSON.parse(localStorage.getItem('scrollPosition'));
    if (scrollPosition) {
      // Scroll to the position saved in localStorage
      window.scrollTo(0, scrollPosition);
      // Clear the saved scroll position from localStorage after using it
      localStorage.removeItem('scrollPosition');
    }
  }, []);












  const [topto, settopto] = useState(0);
  const [bottomto, setbottomto] = useState(0);
  const [IsFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const divElement = document.getElementById("myDiv"); // आपके डिव का आईडी यहां उपयोग करें
    const end = document.getElementById("endsidebar"); // आपके डिव का आईडी यहां उपयोग करें
    if (divElement) {
      const rect = divElement.getBoundingClientRect();
      const distanceFromTop = rect.top;
      settopto(distanceFromTop);
    }
    if (end) {
      const rect2 = end.getBoundingClientRect();
      const distanceFromTop2 = rect2.top;
      setbottomto(distanceFromTop2);
    }
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > topto && scrollPosition < bottomto) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  window.addEventListener("scroll", handleScroll);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const redirecthead = (num) => {
    section1.current?.scrollIntoView({ behavior: "smooth" });
  };

  const redirecthead2 = (num) => {
    section2.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead3 = (num) => {
    section3.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead4 = (num) => {
    section4.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead5 = (num) => {
    section5.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead6 = (num) => {
    section6.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead7 = (num) => {
    section7.current?.scrollIntoView({ behavior: "smooth" });
  };
  const redirecthead8 = (num) => {
    section8.current?.scrollIntoView({ behavior: "smooth" });
  };
 
const nvg = useNavigate()
const navigatefeature = (num) =>{
nvg('/',{state:{id:num}})
}

  return (
    <div style={{margin:'0px',padding:'0px'}}>

<section>
  <nav className="navbar navbar-expand-lg navbar-light bg-body p-0" aria-label="Eighth navbar example">
    <div className="container-fluid">
      <div className="img-fluid pb-1 imgdivsize">
        <NavLink to="/">
          <img src={img3} className='imgsize' alt="logo" /></NavLink>
      </div>
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarsExample07" style={{width:'61%'}}>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(0)}} role="button" >
            Features
            </p>
          </li>
          <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(1)}}>Industries</p>
          </li>
          {/* <li className="nav-item">
            <p className="nav-link text-black" style={{cursor:'pointer',marginBottom:"0px"}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li> */}
          <li className="nav-item dropdown">
            <a className="nav-link text-black" href="/about">About </a>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link text-black" to="/contact">Contact Us</NavLink>
          </li>
        </ul>
        
        <div className=" p-1 d-flex" style={{gap:'4px'}}>
        <li className="nav-item">
            <p className="bg-white text-primary btn btn-outline-primary" style={{cursor:'pointer',marginBlock:'0px'}} onClick={()=>{navigatefeature(2)}}>Register</p>
          </li>
        <NavLink to="/login" className="btn btn-outline-primary">
            Log in
      </NavLink>
          {/* <button type="button" className='btn' >
            <NavLink to='/cart'>
           <FaShoppingCart style={{fontSize:'24px',color:'red'}} />
           </NavLink>
          </button> */}
        </div>
      </div>
    </div>
  </nav>
</section>




{/* <section class="banner-policy"> */}
<section class="banner-term">
        <div class="react-overlay12" >
        {/* <div class="react-overlay121" > */}
            <div class="container-fuild">
                <div class="row m-0 d-flex justify-content-center">
                    <h4 class=" mt-4 bann-txt page-title ">Privacy Policy of KYC First Solution LLP</h4>
                    {/* <p className='text-white text-center spaceofx'>Our innovative KYC solutions enable businesses across various industries to verify the identities of their customers efficiently, securely, and in a user-friendly manner.</p> */}
                    {/* <div className='d-flex justify-content-center'>
                        
                        <button type="button" className="btn btn-outline-primary" style={{background:'transparent',border:'1.4px solid white',color:'white',fontWeight:'500'}}>
                    Get Started
          </button>
                        </div> */}
                </div>

            </div>
        </div>
    </section>


<section>
<div className="container-fluid">
    <div className="row py-3">
      
        <div className="col-md-8 px-3">
          <div className="section1" style={{width:'100%'}}>
            <p className='desc120'>At KYC First Solution LLP, we are committed to safeguarding the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us. By using our services and providing your personal information, you consent to the practices described in this policy. We encourage you to read this Privacy Policy carefully to understand how we handle your data.</p>
          </div>

          <div className="section1 pt-2" style={{width:'100%'}}>
            <p className='title120' ref={section1}>1. Information We Collect</p>
            <p className='desc120 pb-2'>We collect various types of information to provide and improve our services:</p>

            <p className='desc120 pb-2'><span style={{fontWeight:'700'}}>a. Personal Information </span>: When you use our services, we may collect personal information such as your name, email address, contact number, and other details you voluntarily provide during the registration or verification process.</p>
            <p className='desc120 pb-2'> <span style={{fontWeight:'700'}}> b. Identity Verification Data </span> : To comply with KYC regulations, we may collect government-issued identification documents, biometric data, and other information necessary for identity verification.</p>
            <p className='desc120 pb-2'> <span style={{fontWeight:'700'}}> c. Usage Information </span> : We may collect information about how you interact with our website, applications, and services. This includes your IP address, device type, browser type, pages visited, and other usage data.</p>
            <p className='desc120'> <span style={{fontWeight:'700'}}> d. Cookies and Similar Technologies</span> : We use cookies and similar technologies to enhance your user experience, monitor website traffic, and collect usage information. You can manage your cookie preferences through your browser settings.</p>
            
          </div>

          <div className="section1 pt-2" style={{width:'100%'}}>
            <p className='title120' ref={section2}>2. How We Use Your Information</p>
            <p className='desc120 pb-2'><span style={{fontWeight:'700'}}>a. Identity Verification </span>: We use the personal information and identity verification data you provide to verify your identity, prevent fraud, and comply with applicable regulations.</p>
            <p className='desc120 pb-2'> <span style={{fontWeight:'700'}}>  b. Service Improvement  </span>: Your information helps us improve our services, customize user experiences, and develop new features to better serve you.</p>
            <p className='desc120 pb-2'> <span style={{fontWeight:'700'}}>c. Communication </span>: We may use your email address or contact number to send you important updates, service announcements, and marketing materials. You can opt-out of marketing communications at any time.</p>
            <p className='desc120'> <span style={{fontWeight:'700'}}> d. Legal Compliance</span> : We may disclose your information to comply with legal obligations, respond to lawful requests from government authorities, and protect our rights and the rights of others.</p>
            
          </div>

          <div className="section1 pt-2" style={{width:'100%'}}>
            <p className='title120' ref={section3}>3. Data Security</p>
            <p className='desc120'>We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet or electronic storage is entirely secure. While we strive to protect your data, we cannot guarantee its absolute security.</p>
          </div>


          <div className="section1 pt-2" style={{width:'100%'}}>
            <p className='title120' ref={section4}>4. Data Retention</p>
            <p className='desc120'>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
          </div>


          <div className="section1 pt-2" style={{width:'100%'}}>
            <p className='title120' ref={section5}>5. Third-Party Disclosure</p>
            <p className='desc120'>We may share your personal information with trusted third-party service providers to facilitate our services or assist in identity verification. These third parties are contractually obligated to protect your data and can only use it for specified purposes.</p>
          </div>
          <div className="section1 pt-2" style={{width:'100%'}}>
            <p className='title120' ref={section6}>6. Your Rights</p>
            <p className='desc120'>You have the right to access, update, correct, or delete your personal information in our records. You can also request the restriction of processing or object to the processing of your data. To exercise your rights or raise any concerns about your privacy, please contact us using the information provided below.</p>
          </div>
          <div className="section1 pt-2"  style={{width:'100%'}}>
            <p className='title120 ' ref={section7}>7. Changes to this Privacy Policy</p>
            <p className='desc120' id='endsidebar'>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates will be posted on this page, and the effective date will be revised accordingly.</p>
          </div>




          <div className="section1 pt-2"  style={{width:'100%'}}>
            <p className='title120' ref={section8}>8. Contact Us</p>
            <p className='desc120 pb-1'>If you have any questions, concerns, or inquiries regarding these Terms and Conditions or our services, please contact us at:</p>
            <p className='desc120 m-0'>KYC First Solution LLP</p>
            <p className='desc120 m-0'>907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS</p>
            <p className='desc120 m-0'>Email: <span style={{color:'rgba(12, 83, 152, 1)'}}>legal@kycfirst.io</span></p>
            <p className='desc120'>Phone: <span style={{color:'rgba(12, 83, 152, 1)'}}>+91 9730074506</span></p>
          </div>
        </div>
        <div
              className="col-md-4"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className=" row stickylinks"
                style={{
                  width: IsFixed == false ? "107%" : "31%",
                  position: IsFixed == false ? "relative" : "fixed",
                  top: IsFixed == false ? "0px" : "14px",
                }}
              >
                <div className="col-12">
                  {" "}
                  <ul
                    class="list-group hideul"
                    style={{ boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)" }}
                  >
                    {/* <li class="list-group-item active" aria-current="true">An active item</li> */}
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead}
                    >
                     1. Information We Collect
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      id="myDiv"
                      onClick={redirecthead2}
                    >
                     2. How We Use Your Information
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead3}
                    >
                     3. Data Security
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead4}
                    >
                     4. Data Retention
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead5}
                    >
                    5. Third-Party Disclosure
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead6}
                    >
                  6. Your Rights
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead7}
                    >
                      7. Changes to this Privacy Policy
                    </li>
                    <li
                      class="list-group-item" style={{cursor:'pointer'}}
                      onClick={redirecthead8}
                    >
                    8. Contact Us
                    </li>
                
                  </ul>{" "}
                </div>
              </div>
            </div>
        </div>
        </div>
</section>



<footer className="footer" style={{marginTop: '5rem'}}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-3 col-md-6 col-12">
        <div className="footer-img pb-1">
          <img src={img3} style={{mixBlendMode:'darken'}} className='footwidth'  alt="logo" />
        </div>
      </div>
      {/* <div className="col-lg-2 col-md-6 col-12 " >
        <p className="customunder">Home</p>
        <p className="desc21 ">Industries</p>
      </div> */}
      <div className="col-lg-2 col-md-6 col-12 newjc" style={{display:'flex'}}>
        {/* <p className="customunder">Pricing</p> */}
        <p className="desc21 "><NavLink style={{color:'black'}} to='/contact'>Contact Us</NavLink></p>
      </div>
      <div className="col-lg-3 col-md-6 col-12 ">
      <p> <a style={{ color: "black" }} href="/about">About</a></p>

        <p className="desc21 "><a style={{color:'black'}} href="/privacypolicy"> Privacy & Policy</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/termandcondition">Terms & Condition</a></p>
        <p className="desc21"><a style={{color:'black'}} href="/refund">Refund and Cancellation</a></p>
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        <div className="single-footer">
          {/* <p className="customunder">Contact Us</p> */}
          <div className="contact_i">
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><ImLocation2 /></div>
              <div className="col-10  desc21">907 Clover Hill Plaza NIBM Road Kondhwa Pune 411048 MS</div>
            </div>

            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><FaEnvelope /></div>
              <div className="col-10 desc21">Email:<a className=" text-decoration-none" href="mailto:legal@kycfirst.io">
               legal@kycfirst.io</a></div>
            </div>
            <div className="row mt-2">
              <div className="col-1" style={{color:'rgba(12, 83, 152, 1)'}} ><BsFillTelephoneFill /></div>
              <div className="col-10 desc21"> Phone:<a className=" text-decoration-none" href="tel:8130515661">

                 +91 9730074506</a>
              </div>
            </div>
           
          
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="row ">
      <div className="col-12" style={{display:'none'}}>
      <ul className="social-foo">
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaFacebookF /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaInstagram /></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaTwitter/></a></li>
          <li style={{display:'flex',justifyContent:'center',alignItems:'center',width:'50px'}}><a href="#"><FaLinkedinIn /></a></li>
        </ul>
      </div>
      <div className="col-12 mt-1 mb-1">
        <p className="text-center desc2">© 2023 - All Right Reserved</p>
      </div>
    </div>
  </div>
</footer>








    </div>
  )
}

export default Privacypolicy