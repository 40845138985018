import "./App.css";
// import Home from "./Home";
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Termandcondition from "./pages/Termandcondition";
import Privacypolicy from "./pages/Privacypolicy";
import Refund from "./pages/Refund";
// import About from "./About";
// import Contact from "./Contact";
// import Blog from "./Blog";

function App() {
 

  return (
    <div className="App">
      <div style={{background: 'rgb(243, 246, 250)'}}>
      <Routes >
   <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />     
      <Route path="/contact" element={<Contact />} />    
      <Route path="/termandcondition" element={<Termandcondition />} /> 
      <Route path="/privacypolicy" element={<Privacypolicy />} />
      <Route path="/refund" element={<Refund />} />
      {/* <Route path="*" element={<Error />} /> */}
    </Routes>
      </div>
     
    </div>
  )

}

export default App;
